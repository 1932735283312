@import './mixin.scss';
*::-ms-clear {
    display: none;
}

* {
    outline: none !important;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-family:
        Be Vietnam Pro,
        sans-serif;
}

html {
    width: 100vw;
    overflow-x: hidden;
    // overflow-y: hidden;

   @media screen and (min-width: 1200px) {
        overflow-y: hidden;
    }


    &.has-modal {
        overflow: hidden;
    }
}

html,
body {
    font-family: 'Be Vietnam Pro', sans-serif;
    color: var(--text-primary);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    // color: $main;
    background: #fff;
    font-stretch: normal;
    // min-width: 1020px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    direction: ltr;
    text-align: left;
    -webkit-font-smoothing: subpixel-antialiased !important;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    margin: 0;
    padding: 0;

    @include for-tablet {
        align-items: baseline;
    }
   
}

textarea,
input.text,
input[type='text'],
input[type='button'],
input[type='submit'],
input[type='password'],
input[type='email'],
.input-checkbox {
    -webkit-appearance: none;
    font-family: 'Be Vietnam Pro', sans-serif;
}

input::-webkit-contacts-auto-fill-button {
    position: absolute;
    right: 0;
    display: none !important;
    visibility: hidden;
    pointer-events: none;
}

input:focus::-webkit-textfield-decoration-container {
    visibility: hidden;
    pointer-events: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #f7f8fa !important;
    -webkit-text-fill-color: $dark !important;
    color: $dark !important;
}

input {
    border: 1px solid #dbe2ef;
    border-radius: 8px !important;
    padding: 8px 12px;
    width: 100%;
    max-width: 100%;
    font: inherit;
}

button {
    cursor: pointer;
}

select,
textarea,
input {
    border: 1px solid $light-grey;
    border-radius: 8px !important;
    padding: 8px 12px;
    width: 100%;
    max-width: 100%;
    font: inherit;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

img {
    display: block;
}

a {
    text-decoration: none;
    cursor: pointer;
}

button {
    position: relative;
    width: auto;
    padding: 0 20px;
    margin: 0;
    font-family: 'UniNeue', sans-serif;
    overflow: hidden;
    background: transparent;
    border: none;
}

.wrapper {
    position: relative;
    padding: 0 10px;
    height: 100vh;
    overflow: auto;

    & > div {
        // max-width: 1920px;
        width: 100%;
        margin: 0 auto;
    }
}

.media-mobile {
    display: none;
}

.link {
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $blue;
    transition: color 0.2s linear;

    &:hover {
        color: #557ca2;
    }

    &:active {
        color: #254566;
    }
}

.secondary-link {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Be Vietnam Pro', sans-serif;
    color: $color-link;
    transition: color 0.2s linear;

    &:hover {
        color: #557ca2;
    }

    &:active {
        color: #254566;
    }
}

.page-title {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -0.3px;
    color: $color-primary;
    font-family:
        Be Vietnam Pro,
        serif;
    padding: 35px 0 15px 0;
}

.tooltip {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 20;
    padding: 5px 10px;
    font-size: 12px;
    letter-spacing: -0.1px;
    color: $dark;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    border: solid 1px $border-color;
    border-radius: 3px;
    transition: opacity 0.2s linear;
    opacity: 0;
    visibility: hidden;
    font-family: 'Be Vietnam Pro';

    .arrow {
        position: absolute;
        top: -5px;
        left: 20px;
        width: 10px;
        height: 5px;
        overflow: hidden;

        &:before {
            position: absolute;
            top: 1px;
            left: 0;
            display: block;
            width: 6px;
            height: 6px;
            content: '';
            border: 1px solid #e5e9f1;
            transform: rotate(45deg);
            background: #fff;
        }
    }
}

.checkbox-agreement {
    font-size: 13px;
    line-height: 1.31;
    color: $dark-old;

    a {
        font-size: 13px;
        line-height: 1.31;
        color: $dark-old;
        border-bottom: 1px solid $dark-old;

        &:hover {
            color: $dark;
            transition: color 0.2s linear;
        }
    }
}

.button {
    border-radius: 8px;
}
