@import '../../../styles/variables';
.checkbox {
  display: flex;
  position: relative;
  cursor: pointer;
  color: $dark;
  align-items: center;

  &:focus .control {
    border: solid 1px #519cec;
  }

  .control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: #f7f8fa;
    border: solid 1px #e2e7ed;
    flex-shrink: 0;

    .icon-checkbox {
      display: none;
      font-size: 6px;
      color: #fff;
    }
  }

  .text {
    padding-left: 8px;
  }

  .label {
    font-size: 15px;
    color: $dark;
    display: inline;
    font-weight: normal;
    white-space: nowrap;
    text-transform: none;
  }

  .hint {
    display: inline-block;
    position: relative;
    right: 15px;
    bottom: 1px;
  }

  &.active {
    .control {
      border-color: $blue;
      background-color: $blue;

      .icon-checkbox {
        display: block;
        color: #fff; 
        background-image: url('../../../assets/icons/check.svg');
        width: 16px;
        height: 16px;
        background-size: contain;
        background-repeat: no-repeat; 
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .label {
      color: #9ea0a2;
    }
  }

  &.highlighted {
    .label {
      font-size: 18px;
      font-weight: 600;
    }
  }

  &__error {
    position: absolute;
    color: var(--error);
    bottom: -21px;
    margin: 0;
    font-size: 13px;
  }

  
}

.checkbox.isErrorMargin {
    margin-bottom: 10px;
}


@media screen and (max-width: $phone-width) {
	.checkbox {
		.label {
			white-space: wrap;
		}
	}
}