@import '../../../styles/variables';
.input-label {
    position: relative;

    &__label {
        position: absolute;
        top: -10px;
        left: 12px;
        font-size: 14px;
        font-weight: 400;
        background-color: #fff;
        color: #4e6d8b;
        text-transform: capitalize;
    }

    &__input {
        color: #4e6d8b;
        border-radius: 8px;
        width: 100%;
    }

    &__error {
        color: $error;
        margin: 5px 0;
        font-size: 14px;
        a {
            text-decoration: underline;
            font-weight: 400;
            font-size: 14px;
        }
    }
    .error {
        border: 1px solid $error;
    }
}
