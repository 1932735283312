@import 'styles/global.scss';

.react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.modal-container {
    .modal-box {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        border-radius: 12px;
        padding: 12px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);

        &.small {
            width: 330px;
            padding: 24px 16px;
        }

        &.medium {
            width: 600px;
        }

        &.large {
            width: 800px;
        }

        &.huge {
            width: 1000px;
            max-width: 1000px;
        }

        .modal-header {
            font-family: 'Be Vietnam Pro', sans-serif;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: -0.3px;
            color: $color-primary;
            margin: 0 20px 20px 0;
            text-align: left;
        }

        .scrollbar-padding {
            overflow: auto;
            padding: 0 12px 0 0;
        }

        .icon-close {
            position: absolute;
            display: block;
            top: 16px;
            right: 20px;
            width: 16px;
            height: 16px;
            padding: 0;
            cursor: pointer;
        }

        .title {
            font-family: 'Be Vietnam Pro';
            font-size: 28px;
            font-weight: bold;
            letter-spacing: -0.3px;
            color: $dark-blue;
            margin: 0 20px 20px 0;
            text-align: left;

            &.small {
                font-size: 14px;
                font-weight: 300;
            }
        }

        .description {
            color: #519cec;
            font-family: 'Be Vietnam Pro';
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.3px;
            margin-top: -20px;
            margin: 0 20px 20px 0;
            text-align: left;

            &.small {
                font-size: 12px;
                font-weight: 300;
            }
        }

        .modal-btns-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            & > *:not(:last-child) {
                margin-right: 12px;
            }
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .modal-container {

        max-height: calc(100vh - 2rem);
        width: 100%;
        .modal-box {
            margin: 1rem;

            &.large,
            &.medium,
            &.huge {
                width: calc(100% - 2rem);
            }
        }
    }

    .modal-container {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        .modal-box {
            display: inline-block;
        }
    }
}

@keyframes olimpModalIn {
    0% {
        opacity: 0;
        transform: scale(1.1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes olimpModalOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.1);
    }
}
