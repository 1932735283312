@import '../../../styles/variables';
.hint {
    position: relative;
    background-color: rgba(27, 51, 98, 0.65);
    border-radius: 50%;
    margin-left: 20px;
    font-size: 12px;
    color: #677381;
    text-align: center;
    font-weight: 500;
    cursor: default;
    text-transform: none;
    .react-tooltip-lite {
    }

    .react-tooltip-lite-arrow {
    }

    &:hover {
        & > .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }

    .icon {
        display: block;
        line-height: 16px;
        width: 16px;
        height: 16px;
        color: #ffffff;
    }

    &__tooltip {
        .react-tooltip-lite-arrow {
            position: relative;
        }

        .react-tooltip-lite-arrow::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            z-index: 99;
            display: block;
        }

        > .react-tooltip-lite {
            min-width: 220px;
            padding: 10px !important;
            max-width: 380px !important;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: rgba(27, 51, 98, 1);
            border: 1px solid rgba(6, 23, 53, 0.15) !important;
            border-radius: 8px;
        }
    }

    .tooltip {
        left: 0;
        text-align: left;

        .arrow {
            left: 13px;
        }
    }
}

.react-tooltip-lite-up-arrow {
    border-top-color: $border-color !important;

    &::before {
        border-top: 10px solid white;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        left: -10px;
        top: -11px;
    }
}

.react-tooltip-lite-down-arrow {
    border-bottom-color: $border-color !important;

    &::before {
        border-bottom: 10px solid white;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        left: -10px;
        bottom: -11px;
    }
}

.react-tooltip-lite-right-arrow {
    border-right-color: $border-color !important;

    &::before {
        border-right: 10px solid white;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        right: -11px;
        top: -10px;
    }
}

.react-tooltip-lite-left-arrow {
    border-left-color: $border-color !important;

    &::before {
        border-left: 10px solid white;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        left: -11px;
        top: -10px;
    }
}
