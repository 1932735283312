:root {
    --toastify-color-dark: #47484a;
    --toastify-text-color-dark: #ffffff;
    --toastify-toast-min-height: 16px;
    --toastify-toast-width: 360px;
    --toastify-font-family: inherit;
}

.Toastify__toast {
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 16px;
    animation-duration: 300;
    animation-fill-mode: both;
}

.olimp-toastify {
    &--iframed {
        bottom: 200px;
    }
}

@keyframes slideInUp {
    from {
        transform: translate3d(0, 100%, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.slideInUp {
    animation-name: slideInUp;
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}

.fadeOutDown {
    animation-name: fadeOutDown;
}
