@import '../../../styles/variables';
.iti {
  width: 100%;
  --iti-hover-color: white;
  &__selected-country {

    border-radius: 8px;
  }
}

.iti-wrapper {
  position:relative;
  label {
    z-index: 2;
    position: absolute;
    top: -10px;
    left: 12px;
    font-size: 14px;
    font-weight: 400;
    background-color: #fff;
    color: #4e6d8b;
    text-transform: capitalize;
  }
  .input-error {
    border: 1px solid $error;
  }
}

.iti__selected-flag {
  background: #fff !important;
  border-radius: 8px;
}

