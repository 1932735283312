@import './variables';
@import './normalize.scss';
@import './common.scss';
@import './mixin.scss';

.sign-up {
    min-height: 400px;
    position: relative;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    &__wrap {
        display: flex;
        // width: 1920px;
        width: 100vw;
        justify-content: center;
        height: 100%;
    }

    &__logo {
        cursor: pointer;
        margin-right: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    &__uberLogo {
        width: 50px;
        height: 50px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            background-color: $light-grey2;
            height: 50px;
            top: 0;
            right: -8px;
            width: 1px;
        }
    }

    &__uberEntity {
        height: 50px;
        margin-left: 16px;

        &__image {
            height: 50px;
            margin-top: 6px;
        }
    }

    &__entityTitle {
        font-size: 20px;
        font-weight: 600;
        color: $color-primary;
        margin: 0;
        text-transform: capitalize;
    }

    &__header {
        display: flex;
        font-family: Be Vietnam Pro;
        font-size: 20px;
        font-weight: 600;
        color: $color-primary;
        align-items: center;
        align-content: center;
        margin-bottom: 20px;
        height: 50px;
    }

    &__back {
        color: $grey-blue;
        margin-bottom: 9px;
    }

    &__backTitle {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 75px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        color: var(--light-grey);
        line-height: 32px;
    }

    &__logoTitle {
        font-size: 20px;
        max-width: 365px;
        white-space: nowrap;
    }

    &__inputAddress {
        width: 440px !important;
        .label-wrap {
            position: absolute;
            top: -10px;
            z-index: 1;
            left: 12px;
            font-family: Be Vietnam Pro;
            background-color: #fff;
        }

        .label {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            color: $color-primary;
        }
        input {
            background-color: #fff;
        }
    }

    &__leftSide {
        display: flex;
        flex: 1;
        justify-content: center;
        box-sizing: border-box;
        padding-top: 155px;

        .progress {
            display: flex;
            height: 4px;
            background: rgba(16, 24, 69, 0.12);
            width: 440px;
            margin-bottom: 20px;
            border-radius: 10px;

            .progress-fill {
                display: flex;
                height: 4px;
                background: #0071F0;
                border-radius: 10px;
            }
        }
        svg path {
            fill: $grey-blue;
        }
    }
    &__rightSide {
        display: flex;
        padding-right: 20px;
        flex: 1;
    }

    &__title {
        font-family: Be Vietnam Pro;
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 20px;
        color: $color-primary;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        width: 440px;
        margin-bottom: 20px;

        .label {
            white-space: wrap;
        }
    }

    &__input {
        box-sizing: border-box;
        width: 100%;

        &_half {
            max-width: 210px;
            width: 100%;

            @include for-tablet {
                max-width: 100%;
                padding-right: 0;

                &:first-child {
                    padding-right: 12px;
                }
            }
        }
        &_phone {
            max-width: 210px;
            width: 100%;

            @include for-tablet {
                max-width: 100%;
                padding-right: 0;

                &:first-child {
                    padding-right: 12px;
                }
            }

            .error-message {
                color: var(--error);
                margin: 5px 0;
                font-size: 13px;
            }

        }
    }

    &__verification {

        &_center {
            display: flex;
            justify-content: center;
            text-align: center;
        }
        
        &__text {
            font-size: 24px;
            font-weight: 600;
            color: $color-primary;
            margin: 0;
            margin-bottom: 20px;

            @include for-mobile {
                overflow-wrap: anywhere;
            }
        }

        &_error {
            text-align: center;
            color: var(--error);
            font-size: 24px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 20px;
        }
    }

    &__agreement {
        color: var(--text-primary);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .label,
        .link {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }

        .label {
            color: $color-primary;
        }
    }

    &__link {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-primary;
    }

    &__btn {
        margin-bottom: 20px;
    }

    &__carousel {
        position: relative;
        overflow: hidden;

        &__image {
            width: 50vw;
            height: calc(100vh - 40px);
            margin-top: 20px;
            margin-bottom: 20px;
            border-radius: 20px;
            object-fit: cover;

            @include for-tablet {
                width: 100%;
                height: 100%;
                margin: 0;
            }

           
        }

        &__content {
            position: absolute;
            bottom: 0;
            color: #fff;
            width: 100%;
            left: 100px;
            bottom: 100px;
            max-width: 70%;
        }

        &__title {
            color: #fff;
            text-shadow: 0px 0px 12px rgba(6, 23, 53, 0.5);
            font-family: Be Vietnam Pro;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
            margin-bottom: 20px;

            @include for-laptop {
                font-size: 24px;
            }

        }
        &__text {
            color: #fff;
            text-shadow: 0px 0px 12px rgba(6, 23, 53, 0.5);
            font-family: 'Be Vietnam Pro', sans-serif;
            font-size: 20px;
            font-weight: 700;
            margin: 0;

            @include for-laptop {
                font-size: 16px;
            }
        }
    }

    &__signInLink {
        margin-top: 20px;
    }

    &__existCompany {
        margin-top: 20px;
        display: block;
        text-decoration: underline;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
    }

    &__text {
        color: var(--text-primary);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__companyEmail {
        width: 440px;
        height: 65px;
        display: flex;
        align-items: center;
        background-color: var(--blue-grey);
        padding: 20px;
        border-radius: 12px;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: var(--text-primary);
    }

    &__logo2 {
        display: flex;
        position: relative;
       
        &::after {
            content: '';
            position: absolute;
            border-right: 1px solid $light-grey2;
            height: 50px;
            right: 0px;
        }
    }

    &__companyLogo {
        position: relative;
        padding-left: 8px;
        max-width: 370px;
        
        img{
            max-height: 50px;
            margin: 0;
            width: auto;
            max-width: 200px;
        }
    }

    &__companyTitle {
        font-size: 20px;
    }

    &__verify {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;

        &__title {
            font-size: 18px;
            color: var(--text-primary);
            display: flex;
            max-width: 400px;
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            button{
                width: 120px;
                margin-top: 12px;
            }
        }

        &__error {
            color: var(--error);
        }
    }

    &__googleInput {
        width: 440px;
    }

    &__backButtonText {
        display: inline-block;
        padding-left: 4px;
    }
}


@media screen and (max-width: $extra-large-width) {
   .sign-up {
        &__wrap {
            // width: 100%;
        }
    }
}

@media screen and (max-width: $medium-laptop-width) {
   .sign-up {
       &__leftSide {
            padding-top: 0;
            align-items: center;
        }
    }
}

@media screen and (max-width: $laptop-width) {
    .sign-up {
        &__carousel {
            &__content {
                left: 0;
                max-width: 100%;
                padding: 12px;
                top: 10%;
            }
        }

         &__googleInput,
         &__location {
            min-width: 100%;
            width: 100%;
         }

         &__leftSide {
            padding-top: 50px;
         }
    }
}


@media screen and (max-width: $tablet-portrait-width) {
    .wrapper {
        padding: 0;
    }

   .sign-up {
        &__wrap {
            flex-direction: column;
            width: 100%;
            padding: 20px 12px;
        }

        &__row {
            width: 100% !important;

            &_mb {
                margin-bottom: 0;
            }
        }

        &__leftSide {
            width: 100%;
            display: block;
            margin: 0;
            padding: 0;
            margin-bottom: 20px;
            

            .progress {
                width: 100%;
            }

            .selection-card {
                width: 100%;
            }
        }
        &__rightSide {
            width: 100%;
            display: block;
            padding: 0;
            margin: 0;
        }

        &__carousel {
            &__content {
                left: 0;
                max-width: 100%;
                padding: 12px;
                top: 0;
            }

            &__title {
                font-size: 24px;
            }

            &__text {
                font-size: 16px;
            }
        }

         &__companyEmail {
            width: 100%;
        }
   }
}

@media screen and (max-width: $phone-width) {
    .sign-up {
        &__wrap {
            width: 100vw;
        }
        
        &__carousel {
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color:#00000050;
                border-radius: 20px;
            }

            &__image {
                height: auto;
                width: 100%;
            }

            &__title,
            &__text  {
                text-shadow: 0px 0px 12px rgba(6, 23, 53, 0.50);
            }

            &__text {
                font-weight: 400;
                text-align: justify;
            }
        }

        &__row {
            .sign-up__input_half,
            .sign-up__input_phone {
                &:first-child {
                    padding-right: 12px;
                }
            }
        }

        &__listBtn {
            margin-bottom: 0;
        }

        &__companyLogo {
            max-width: 300px;
        }

        &__companyTitle {
            white-space: wrap;
        }

        &__verification{
            &_center {
                // padding: 50px 0;
                padding-top: 40px;
            }
        }

        &__loader_mb {
            padding-bottom: 40px;
        }
    }
}

.email-notification-settings {
    .mx-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .font-semibold {
        font-weight: bold;
        color: #1b3362;
    }

    .button {
        height: 36px;
        line-height: 36px;
        font-size: 16px;
    }

    a {
        color: #417dbd;
        font-size: 14px;
        text-decoration: underline;
    }
    .mr10 {
        margin-right: 10px;
    }
    .ml10 {
        margin-left: 10px;
    }
}
