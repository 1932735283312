.modal-feedback {
    .tabs {
        margin-bottom: 30px;
    }

    &-inputs {
        & > *:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .btns-wrapper {
        position: relative;
    }

    .btns {
        display: flex;
        justify-content: flex-end;

        & > *:first-child {
            margin-right: 12px;
        }
    }

    .error-message {
        display: block;
        text-align: right;
        max-width: 100%;
        width: 100%;
        left: 0;
    }

    .error-submit {
        font-size: 14px;
        position: unset;
        margin-top: 5px;
    }

    button[type='submit'] {
        width: 80px;
    }

    &__actions {
        margin-top: 20px;
        button {
            height: 36px;
            border-radius: 8px;
            font-size: 16px !important;
        }
        button.bordered {
            border: solid 1px #519cec;
            line-height: 46px;
            color: #519cec;
            background: #fff;
        }
        button.blue {
            background: #519cec;
            color: #fff;
        }
    }
    .sign-up__input_phone {
        width: auto;
        max-width: 100%;
    }
    .icon-close {
        top: 8px !important;
        height: 30px !important;
        width: 30px !important;
        right: 8px !important;
    }
    .textarea-label__error, .input-label__error, .error-message {
        display: block;
        text-align: right;
        max-width: 100%;
        width: 100%;
        left: 0;
        position: absolute;
        top: 100%;
        color: #df5151;
        font-size: 12px;
        line-height: 1.3em;
        z-index: 800;
        margin: 0;
    }
    .textarea-label__label, label {
        text-transform: none;
    }
}
