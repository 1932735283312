@import 'src/styles/_variables.scss';

.toggle-switch {
  display: flex;
  &__label {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    margin-right: 15px;

    .toggle-switch__input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .toggle-switch__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked + .toggle-switch__slider {
      background-color: #2196F3;
    }

    input:checked + .toggle-switch__slider:before {
      transform: translateX(20px);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    width: 80%;

    strong {
      font-size: 14px;
      color: #1B3362;
      font-weight: 600;
      margin-bottom: 4px;
    }

    p {
      font-size: 14px;
      color: #1B3362;
      font-weight: 400;
      margin: 0;
    }
    &.unchecked {
      strong {
        color: #ccc;
        margin-bottom: 0;
        margin-top: 2px;
      }
      p {
        display: none;
      }
    }
  }
}
