@import '../../../styles/variables';

.textarea-label {
    position: relative;

    &__label {
        position: absolute;
        top: -10px;
        left: 12px;
        font-size: 14px;
        font-weight: 400;
        background-color: #fff;
        color: #4e6d8b;
        text-transform: capitalize;
    }

    &__textarea {
        color: #4e6d8b;
        border-radius: 8px;
        width: 100%;
        height: 100px;
        resize: vertical;
        padding: 10px;
        border: 1px solid #ccc;
    }

    &__error {
        color: $error;
        margin: 5px 0;
        font-size: 14px;

        a {
            text-decoration: underline;
            font-weight: 400;
            font-size: 14px;
        }
    }
    .error {
        border: 1px solid $error;
    }
}
