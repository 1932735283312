@import './variables';

// 576px
@mixin for-mobile {
  @media (max-width: $phone-width) {
    @content;
  }
}

// 768px
@mixin for-tablet {
  @media (max-width: $tablet-portrait-width) {
    @content;
  }
}

// 1024px
@mixin for-laptop {
  @media (max-width: $laptop-width) {
    @content;
  }
}

// 1440px
@mixin for-large-screen {
  @media (max-width: $large-width) {
    @content;
  }
}

