@import './variables';
@import './common.scss';
@import './normalize.scss';
@import 'https://fonts.googleapis.com/css?family=Be+Vietnam+Pro:wght@400;600';


:root {
    --text-primary: #203E5E;
    --font: "Be Vietnam Pro";
    --blue-grey: #F1F4FA;
    --light-grey: #7C8CA9;
    --error: #ed6868;
}

.input-location {
    &__wrap {
        position: relative;
    }
    &__label {
        position: absolute;
        top: -10px;
        font-size: 14px;
        font-weight: 400;
        background-color: #fff;
        text-transform: capitalize;
        color: $dark-blue;
        background-color: #fff;
        padding: 0 5px;
        left: 8px;
    }

    &__input {
        color: $dark-blue;
    }

    &__error-message {
        color: $error;
    }
}

.image {
    display: inline-block;
    margin: 0;
    padding: 0;
    border: none;
}

$media-limit-mobile-sm: 768px;
$media-limit-mobile: 1023px;

.card-CD-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > *:not(:last-child) {
        margin-right: 12px;
    }

    .card-delete {
        width: 151px;
    }

    &_left {
        justify-content: flex-start;
    }

    &_right {
        justify-content: flex-end;
    }
}

.card-error-message {
    &_left {
        text-align: left;
    }

    &_right {
        text-align: right;
    }
}