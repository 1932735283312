$main: #5c6064;
$grey: #979ea6;
$silver: #eef0f3;
$light-silver: #f7f8fa;
$red: #fa837a;
$white: #fff;
$black: #000;
$light-blue: #e0f0ff;
$light-blue2: #f6f9ff;
$blue-grey: #f6f9ff;
$blue: #0071F0;
$blue-pressed: #4590df;
$dark-old: #51585f;
$dark: #000;
$orange: #e09c3c;
$yellow: #e0ca3c;
$grey-blue: #9caec5;
$dark-blue: #416283;
$medium-blue: #48617f;
$error: #ed6868;
$error-pressed: #da5858;
$green: #7cce66;
$success: #52a775;
$border-color: #e5e9f1;
$color-primary: #1B3362;
$light-grey: #dbe2ef;
$light-grey2: #1B3362A6;
$color-link: #2887fa;

$extra-large-width: 1650px;
$large-width: 1440px;
$medium-laptop-width: 1366px;
$laptop-width: 1024px;
$tablet-portrait-width: 768px;
$phone-width: 576px;
