@import '../../../styles/variables';
.selection-card {
    position: relative;
    width: 440px;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $light-grey;
    background-color: $light-blue2;
    color: $color-primary;
    cursor: pointer;

    &__title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
    }
    &__content {
        font-size: 14px;
        font-weight: 400;
        max-width: 390px;
        padding-right: 20px;
        box-sizing: border-box;
        line-height: 22px;
    }
    &__icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
}
